
.footer{
    /* background:rgba(0, 128, 0, 0.3); */
    display: flex;
    flex-direction: column;
    
}

.footer .element{
    text-align: center;
    height:80px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease-in-out;
    

}

.footer .element:hover{
   padding:10px;
   height:90px;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
   font-weight: 800;
}

.footer .element a{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serifz;
    font-size: 18px;
}

